import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["frame"]

    element = null
    formElement = null
    alertElement = null
    submitBtn = null

    async displayFormBlock(e) {
        const value = e.currentTarget.value
        if (value) {
            const response = await fetch(value)
            if (response.ok) {
                this.frameTarget.innerHTML = await response.text()
            }
        } else {
            this.frameTarget.innerHTML = ""
        }
    }

    async validatePosition(e) {
        try {
            const response = await fetch(
                "/blocs/" +
                    e.params.pageid +
                    "/" +
                    e.target.value +
                    "/" +
                    (e.params.position ? e.params.position : false),
            )
            const result = await response.json()
            this.positionResolverCallback(e.target, result)
        } catch (e) {
            return "KO" + e
        }
    }

    positionResolverCallback(inputElement, result) {
        this.formElement = inputElement.parentElement
        this.submitBtn = this.formElement.querySelector("button[type='submit']")
        this.setAlertElement(inputElement, result.errorMessage)
        this.enableSubmitButton(result.available)
    }

    setAlertElement(element, errorMessage) {
        this.alertElement = document.querySelector("#errorAlert")
        if (!this.alertElement) {
            const alert = document.createElement("p")
            alert.setAttribute("id", "errorAlert")
            alert.classList.add("typo-caption", "text-red", "hidden")
            alert.innerText = errorMessage ? errorMessage : ""
            element.parentNode.insertBefore(alert, element.nextSibling)
            this.alertElement = document.querySelector("#errorAlert")
        }
        this.alertElement.innerText = errorMessage ? errorMessage : ""
    }

    enableSubmitButton(available) {
        if (available) {
            this.submitBtn.removeAttribute("disabled")
            this.alertElement.classList.add("hidden")
        } else {
            this.submitBtn.setAttribute("disabled", "")
            this.alertElement.classList.remove("hidden")
        }
    }
}
