import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        whenMatching: String,
    }

    get #shouldSubmit() {
        return matchMedia(this.whenMatchingValue).matches
    }

    submit(event) {
        if (this.#shouldSubmit) {
            event.target.form.submit()
        }
    }
}
