import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["span", "input", "details", "contractInput", "contractDiv"]
    static values = {
        contractTranslation: String,
    }

    connect() {
        this.fillSpanWithSelectedValues()
    }

    fillSpanWithSelectedValues() {
        this.spanTarget.innerText =
            this.#selectedValues.length === 0 ? this.contractTranslationValue : this.#selectedValues
    }

    get #selectedValues() {
        const contractToDisplay = []
        for (const checkbox of this.inputTargets) {
            if (checkbox.checked === true) {
                contractToDisplay.push(checkbox.value)
            }
        }
        return contractToDisplay
    }

    closeContractDiv() {
        this.detailsTarget.removeAttribute("open")
        this.contractInputTarget.classList.add("rounded", "border")
    }
    removeBorderInput() {
        ;["border", "rounded"].map((value) => this.contractInputTarget.classList.toggle(value))
    }
}
