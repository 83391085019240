import { Controller } from "@hotwired/stimulus"

/**
 * Class to delete when real sessions will be used
 */
export default class extends Controller {
    static values = {
        host: String,
    }

    connect() {
        this.refreshLegacySession()
    }

    refreshLegacySession() {
        fetch("https://" + this.hostValue + "/session/refresh", {
            method: "GET",
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Problème de réseau ou de réponse")
                }
            })
            .catch((error) => {
                console.error("Problème avec requête fetch: ", error.message)
            })
    }
}
