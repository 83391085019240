import Popover from "stimulus-popover"

export default class extends Popover {
    click(e) {
        e.stopPropagation()
        const spanText = this.cardTarget.getAttribute("data-click-wording")
        const span = e.currentTarget.querySelector('[data-span="js-tooltip-span"]')

        span.textContent = spanText

        // on mobile device there is no mouseleave, so we removed the tooltip after 2sec
        setTimeout(() => {
            this.hasCardTarget && this.cardTarget.remove()
        }, 2000)
    }
}
