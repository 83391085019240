import { Controller } from "@hotwired/stimulus"
import { Editor } from "@tiptap/core"
import { StarterKit } from "@tiptap/starter-kit"
import TextStyle from "@tiptap/extension-text-style"
import { Link } from "@tiptap/extension-link"

export default class extends Controller {
    static targets = ["editorInput", "hiddenInput", "submit", "input"]
    static values = {
        instantiateEditor: Boolean,
    }

    connect() {
        this.inputTargets.forEach((input) => {
            input.addEventListener("keydown", this.preventSubmit.bind(this))
        })
        if (!this.instantiateEditorValue) {
            return
        }
        const editorOptions = this.optionsValue || {}

        this.editorInput = new Editor({
            element: this.editorInputTarget,
            extensions: [
                StarterKit.configure({
                    italic: false,
                    code: false,
                    strike: false,
                }),
                TextStyle,
                Link.configure({
                    openOnClick: false,
                    HTMLAttributes: {
                        target: "_blank",
                    },
                }),
            ],
            ...editorOptions,
            content: this.hiddenInputTarget.value,
            onUpdate: ({ editor }) => {
                this.hiddenInputTarget.value = editor.getHTML()
            },
        })
    }

    toggleBulletList(e) {
        e.preventDefault()
        this.editorInput.chain().focus().toggleBulletList().run()
    }

    setLink(e) {
        const previousUrl = this.editorInput.getAttributes("link").href
        const url = window.prompt("URL", previousUrl)
        e.preventDefault()
        if (url === null) {
            return
        }
        if (url === "") {
            this.editorInput.chain().focus().extendMarkRange("link").unsetLink().run()

            return
        }
        this.editorInput.chain().focus().extendMarkRange("link").setLink({ href: url, target: "_blank" }).run()
    }

    preventSubmit(e) {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    disconnect() {
        this.inputTargets.forEach((input) => {
            input.removeEventListener("keydown", this.preventSubmit.bind(this))
        })
        this.editorInput.destroy()
        if (this.menuElement) {
            this.menuElement.remove()
        }
    }
}
