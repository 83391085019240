import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["alertBtn", "alertBtnFixed"]

    static values = {
        whenMatching: String,
    }

    fixAlertNotificationBell() {
        const alertBtnPosition = this.alertBtnTarget.getBoundingClientRect()

        if (!this.#shouldToggleButtonOnScroll()) {
            if (alertBtnPosition.bottom < 0) {
                this.alertBtnFixedTarget.classList.remove("hidden")
            } else {
                this.alertBtnFixedTarget.classList.add("hidden")
            }
        } else {
            if (window.scrollY > 0) {
                this.alertBtnTarget.classList.add("hidden")
                this.alertBtnFixedTarget.classList.remove("hidden")
            } else {
                this.alertBtnFixedTarget.classList.add("hidden")
                this.alertBtnTarget.classList.remove("hidden")
            }
        }
    }

    #shouldToggleButtonOnScroll() {
        return !window.matchMedia(this.whenMatchingValue).matches
    }
}
