import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "dialog", "frame", "closeBtn"]

    connect() {
        this.element.addEventListener("turbo:submit-end", (event) => {
            this.onSubmitEnd(event)
            this.closeDialog(event)
        })
    }

    disconnect() {
        this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd.bind(this))
    }

    onSubmitEnd(event) {
        const { success, fetchResponse } = event.detail

        if (success) {
            fetchResponse.responseText
                .then((data) => {
                    const { type, message } = JSON.parse(data)
                    this.showToast(
                        type,
                        message ||
                            "Votre alerte emploi a été créée ! vous allez recevoir un email pour valider cette alerte",
                    )
                })
                .catch(() => {
                    this.showToast("warning", "Form submitted successfully!") // Fallback message
                })
        } else {
            this.showToast("warning", "Form submitted successfully!") // Fallback message
        }
    }

    showToast(type, message) {
        const toastContainerByType = document.getElementById("toast-" + type)
        const toastMessage = toastContainerByType.querySelector(".toast-message")

        toastMessage.innerHTML = message
        toastContainerByType.classList.remove("hidden")

        setTimeout(() => {
            toastContainerByType.classList.add("hidden")
        }, 5000)
    }

    openDialog() {
        this.dialogTarget.showModal()
    }

    closeDialog(e) {
        if (e.target === this.dialogTarget || this.closeBtnTarget.contains(e.target) || e.type === "turbo:submit-end") {
            this.dialogTarget.close()
        }
    }

    animationBell(e) {
        e.target.querySelector("img").classList.toggle("ring-animation")
    }
}
