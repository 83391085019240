import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["inputs"]

    getColor(e) {
        let colorTarget = this.inputsTargets.find(({ id }) => id === e.target.name)
        colorTarget.value = e.target.value
    }
}
