import controller_0 from '@symfony/ux-dropzone/dist/controller.js';
import '@symfony/ux-dropzone/dist/style.min.css';
import controller_1 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/dist/live.min.css';
import controller_2 from '@symfony/ux-toggle-password/dist/controller.js';
import '@symfony/ux-toggle-password/dist/style.min.css';
import controller_3 from '@symfony/ux-turbo/dist/turbo_controller.js';
export default {
  'symfony--ux-dropzone--dropzone': controller_0,
  'live': controller_1,
  'symfony--ux-toggle-password--toggle-password': controller_2,
  'symfony--ux-turbo--turbo-core': controller_3,
};