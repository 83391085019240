import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "hiddenInput"]

    fillSuggestion(e) {
        this.inputTarget.value = e.detail.label
        this.hiddenInputTarget.value = JSON.stringify(e.detail)
    }

    clear(e) {
        if (e.target.value !== "") {
            // volontairement pas un JSON pour provoquer une erreur de validation au POST
            this.hiddenInputTarget.value = "-"
        } else {
            this.hiddenInputTarget.value = null
        }
    }
}
