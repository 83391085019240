import { Controller } from "@hotwired/stimulus"
import { getComponent } from "@symfony/ux-live-component"

/* stimulusFetch : 'lazy" */
export default class extends Controller {
    static values = {
        id: Number,
        csrfToken: String,
    }

    dialog = null
    form = null
    formComponent = null

    async initialize() {
        this.dialog = document.getElementById("form-dialog")
        this.form = this.dialog.firstElementChild
        this.formComponent = await getComponent(this.form)
        if (this.dialog !== null) {
            window.addEventListener("dialog:close", () => this.dialog.close("Closing Dialog"))
            window.addEventListener("dialog:open", () => this.dialog.showModal())
        }

        this.form.addEventListener("onClick", (event) => event.stopPropagation())
    }

    newItem() {
        this.formComponent.emit("item:new")
    }

    editItem() {
        this.formComponent.emit("item:edit", { id: this.idValue })
    }

    deleteItem() {
        if (confirm("Voulez-vous supprimer ce contenu ?")) {
            this.formComponent.emit("item:delete", { id: this.idValue, csrfToken: this.csrfTokenValue })
        }
    }
}
