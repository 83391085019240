/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.css"

// start the Stimulus application
import "./bootstrap"

document.addEventListener("turbo:before-fetch-request", (event) => {
    event.detail.fetchOptions.headers["X-CSP-Nonce"] = document.querySelector("meta[name='csp-nonce']")?.content
    event.detail.fetchOptions.headers["X-SymfonyProfiler-Script-Nonce"] =
        document.querySelector("meta[name='csp-nonce']")?.content
    event.detail.fetchOptions.headers["X-SymfonyProfiler-Style-Nonce"] =
        document.querySelector("meta[name='csp-nonce']")?.content
})
