import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        message: String,
    }

    askConfirmation(event) {
        if (!confirm(this.messageValue)) {
            event.preventDefault()
        }
    }
}
